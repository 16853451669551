import { useMemo } from 'react'
import { configure } from 'mobx'
import { observer } from 'mobx-react-lite'
import { ThemeProvider, createTheme } from '@mui/material'
import { ToastContainer } from 'react-toastify'

import { SignIn } from 'pages'
import { useRootStore, RootStoreProvider } from 'stores'
import 'react-toastify/dist/ReactToastify.css'

import Router from './Router'
import lightTheme from './theme/light'

configure({
  reactionScheduler: (f) => setTimeout(f)
})

function App() {
  const { authorization } = useRootStore()
  const theme = useMemo(() => createTheme(lightTheme), [])

  document.body.style.backgroundColor = theme.palette.background.default

  return (
    <ThemeProvider theme={theme}>
      <RootStoreProvider>{authorization.isAuthorized ? <Router /> : <SignIn />}</RootStoreProvider>
      <ToastContainer />
    </ThemeProvider>
  )
}

export default observer(App)
