import { RxExit } from 'react-icons/rx'

import { ROUTES } from 'shared/consts'

export const config = [
  {
    title: 'Города',
    to: ROUTES.CITIES
  },
  {
    title: 'Вакансии',
    to: ROUTES.VACANCIES
  },
  {
    title: 'Менеджер',
    to: ROUTES.MANAGER
  },
  {
    title: 'О компании',
    to: ROUTES.ABOUT_COMPANY
  },
  {
    title: 'FAQ',
    to: ROUTES.FAQ
  },
  {
    icon: <RxExit />,
    title: 'Выйти',
    to: 'unauthorize'
  }
]
