import axios from 'axios'
import { getQueryParams } from 'shared/helpers'

import { City } from 'shared/types'

const ENDPOINT_BASE_ADMIN = '/api/v1/cities'
const { REACT_APP_BASE_URL } = process.env

export const get = async ({
  searchParams
}: {
  searchParams?: { [key: string]: string }
}): Promise<City[]> => {
  const accessToken = localStorage.getItem('ACCESS_TOKEN')
  const config = {
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  }

  const response = await axios.get<City[]>(
    `${REACT_APP_BASE_URL}${ENDPOINT_BASE_ADMIN}${getQueryParams({
      ...searchParams
    })}`,
    config
  )
  return response.data
}

export const getById = async (id: number): Promise<City> => {
  const accessToken = localStorage.getItem('ACCESS_TOKEN')
  const config = {
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  }

  const response = await axios.get<City>(
    `${REACT_APP_BASE_URL}${ENDPOINT_BASE_ADMIN}/${id}`,
    config
  )
  return response.data
}

export const create = async (city: City) => {
  const accessToken = localStorage.getItem('ACCESS_TOKEN')
  const config = {
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  }

  await axios.post(`${REACT_APP_BASE_URL}${ENDPOINT_BASE_ADMIN}`, city, config)
}

export const deleteById = async (id: number) => {
  const accessToken = localStorage.getItem('ACCESS_TOKEN')
  const config = {
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  }

  await axios.delete(`${REACT_APP_BASE_URL}${ENDPOINT_BASE_ADMIN}/${id}`, config)
}

// export const update = async (product: Product) => {
//   await axios.put(`${REACT_APP_BASE_URL}${ENDPOINT_BASE_ADMIN}`, product, config)
// }
