import { makeAutoObservable } from 'mobx'

import LoadingModel from 'models/Loading'
import { API } from 'services'
import { City, Vacancy } from 'shared/types'

class VacancyInfoModel {
  name: string = ''
  description: string = ''
  advantages: string = ''
  payment: string = ''
  cities: { id?: string; name?: string }[] = []
  image: any = null
  image_url? = ''

  citiesNames: City[] = []
  loading: LoadingModel

  constructor() {
    makeAutoObservable(this)

    this.loading = new LoadingModel()
  }

  async fetch({ id }: { id: number }) {
    try {
      this.loading.begin()

      const data = await API.cities.get({})
      const vacancyData = await API.vacancies.getById(id)

      this.citiesNames = data
      this.fromJSON(vacancyData)
    } catch (err: any) {
      console.log(err)
    } finally {
      this.loading.end()
    }
  }

  async update({
    vacancy,
    id
  }: {
    vacancy: {
      name: string
      description: string
      advantages: string
      payment: string
      cities: number[]
      image: any
    }
    id: number
  }) {
    try {
      this.loading.begin()

      let formData = new FormData()

      formData.append('name', vacancy.name)

      formData.append('advantages', vacancy.advantages)

      formData.append('description', vacancy.description)

      formData.append('payment', vacancy.payment)

      formData.append('cities', vacancy.cities.join(','))

      if (vacancy.image) {
        formData.append('image', vacancy.image)
      }

      await API.vacancies.update(formData, id)

      await this.fetch({ id: Number(id) })
    } catch (err: any) {
      console.log(err)
    } finally {
      this.loading.end()
    }
  }

  async delete(id: number) {
    try {
      this.loading.begin()

      await API.vacancies.deleteById(id)
    } catch (err: any) {
      console.log(err)
    } finally {
      this.loading.end()
    }
  }

  private fromJSON(data: Vacancy) {
    this.name = data.name
    this.description = data.description
    this.advantages = data.advantages
    this.payment = data.payment
    this.cities = data.cities
    this.image_url = data.image_url
  }
}

const model = new VacancyInfoModel()

export default model
