import { useState } from 'react'
import { observer } from 'mobx-react-lite'
import { Button } from '@mui/material'

import ConfirmDialog from './ConfirmDialog'

interface Props {
  name: string
  onDelete: () => void
}

const DeleteItem = ({ onDelete, name }: Props) => {
  const [isOpenModal, setIsOpenModal] = useState(false)

  return (
    <>
      <Button
        onClick={() => {
          setIsOpenModal(true)
        }}
        sx={{
          mt: 3,
          width: 'fit-content',
          height: 'fit-content',
          p: '4px 32px',
          m: 0
        }}
        variant="contained"
        color="error"
      >
        Удалить
      </Button>

      <ConfirmDialog
        open={isOpenModal}
        handleClose={() => {
          setIsOpenModal(false)
        }}
        onDelete={onDelete}
      />
    </>
  )
}

export default observer(DeleteItem)
