import { useRef } from 'react'
import { useFormikContext } from 'formik'
import { observer } from 'mobx-react-lite'
import { Button, Box, Avatar, IconButton, Typography, SxProps, Theme } from '@mui/material'

import { TfiGallery } from 'react-icons/tfi'
import { BsCamera } from 'react-icons/bs'

import s from './Styles.module.scss'

interface Props {
  field: string
  imageUrl?: string
  imgSx?: SxProps<Theme>
  readonly?: boolean
}

function UploadImageField({ field, imageUrl = '', imgSx, readonly = false }: Props) {
  const inputRef = useRef<any>()
  const { touched, values, errors, setFieldValue } = useFormikContext<{
    [key: string]: any
  }>()

  const hasError = touched[field] && errors[field]

  const action = values[field] ? 'Изменить' : 'Выбрать'

  function handleClick() {
    inputRef.current?.click()
  }

  return (
    <>
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, my: 2 }}>
        <Box
          sx={{
            border: ({ palette }) =>
              `1px solid ${hasError ? palette.error.main : palette.grey[400]}`,
            borderWidth: 1,
            p: 0.5
          }}
          onClick={() => (readonly ? null : handleClick())}
          className={readonly ? null : s.image}
        >
          <Avatar
            style={{ cursor: readonly ? 'default' : 'pointer' }}
            alt="Upload"
            src={values[field] ? URL.createObjectURL(values[field]) : imageUrl}
            sx={{ width: 300, height: 380, ...imgSx }}
            className={readonly ? null : s.imageHover}
          >
            <IconButton
              sx={{
                cursor: readonly ? 'default' : 'pointer',
                color: ({ palette }) => palette.text.primary
              }}
              size="large"
              className={readonly ? null : s.galleryIcon}
            >
              <TfiGallery />
            </IconButton>
          </Avatar>

          {!readonly && (
            <Box className={s.cameraIcon}>
              <BsCamera />
              <Typography variant="subtitle2">Выбрать</Typography>
            </Box>
          )}
        </Box>

        {!readonly && (
          <Button
            onClick={handleClick}
            sx={{
              color: ({ palette }) => (hasError ? palette.error.main : palette.text.secondary)
            }}
          >
            {hasError ? errors[field] : action}
          </Button>
        )}
      </Box>

      <input
        ref={inputRef}
        id="upload-file"
        name={field}
        type="file"
        accept="image/*"
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          setFieldValue(field, e.target.files?.[0])
        }}
        style={{ display: 'none' }}
      />
    </>
  )
}

export default observer(UploadImageField)
