import jwtDecode from 'jwt-decode'
import { makeAutoObservable } from 'mobx'
import { toast } from 'react-toastify'

import { API } from 'services'
import { RootStore } from 'stores/Root'

const ACCESS_TOKEN = 'ACCESS_TOKEN'

class AuthorizationModel {
  readonly rootStore: RootStore

  accessToken: string | null = null
  isAuthorized: boolean = false

  constructor(rootStore: RootStore) {
    makeAutoObservable(this)
    this.rootStore = rootStore

    this.init()
  }

  init() {
    this.accessToken = this.getAccessToken()
  }

  getAccessToken() {
    const token = localStorage.getItem(ACCESS_TOKEN)
    if (token) {
      const decodedToken: { exp: number } = jwtDecode(token)
      const expirationTime = decodedToken.exp * 1000
      const currentTime = Date.now()

      if (expirationTime < currentTime) {
        this.unauthorize()
      } else {
        this.isAuthorized = true
      }
    }
    return token ?? ''
  }

  setAccessToken(value: string) {
    this.accessToken = value
    localStorage.setItem(ACCESS_TOKEN, value)
  }

  unauthorize() {
    this.isAuthorized = false
    localStorage.removeItem(ACCESS_TOKEN)
  }

  async signIn(value: { login: string; password: string }) {
    try {
      this.rootStore.loading.begin()

      const data = await API.auth.signIn(value.login, value.password)

      this.setAccessToken(data.token)
      this.isAuthorized = true
    } catch (err: any) {
      if (err.response.data.error === 'No such user') {
        toast.error('Введённые данные не верны!')
      } else {
        toast.error(err.response.data.error)
      }
    } finally {
      this.rootStore.loading.end()
    }
  }
}

export default AuthorizationModel
