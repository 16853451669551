import { observer } from 'mobx-react-lite'
import { useFormikContext } from 'formik'
import { TextField } from '@mui/material'

interface Props {
  field: string
  label?: string
  type?: string
  multiline?: boolean
  readonly?: boolean
  rows?: number
  variant?: 'filled' | 'outlined' | 'standard'
  size?: 'large' | 'medium' | 'small'
}

const FormInput = ({
  field,
  label,
  type,
  multiline = false,
  readonly,
  rows = 0,
  variant = 'standard',
  size = 'medium'
}: Props) => {
  const { touched, values, errors, handleChange } = useFormikContext<{
    [key: string]: any
  }>()

  return (
    <TextField
      fullWidth
      placeholder={label}
      id={field}
      name={field}
      type={type}
      value={values[field]}
      onChange={handleChange}
      error={touched[field] && Boolean(errors[field])}
      helperText={touched[field] && errors[field]}
      multiline={multiline}
      rows={rows}
      variant={variant}
      sx={
        size === 'medium'
          ? {
              input: {
                color: (theme) => theme.palette.grey[700],
                lineHeight: '27px',
                fontSize: '18px',
                padding: '10px',
                fontFamily: 'Rubik, sans-serif',
                '&:hover:before': {
                  borderBottomColor: 'rgba(60, 41, 163, 0.40)!important'
                }
              },
              label: {
                color: (theme) => theme.palette.grey[700],
                fontSize: '18px'
              }
            }
          : {
              input: {
                color: (theme) => theme.palette.grey[700],
                lineHeight: '36px',
                fontSize: '24px',
                fontFamily: 'Rubik, sans-serif'
              },
              label: {
                color: (theme) => theme.palette.grey[700],
                fontSize: '24px'
              }
            }
      }
      InputProps={{
        readOnly: readonly,
        style: {
          minHeight: multiline ? '100px' : 'auto',
          alignItems: 'start',
          fontSize: '18px'
        }
      }}
    />
  )
}

export default observer(FormInput)
