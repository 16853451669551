import { makeAutoObservable } from 'mobx'

import LoadingModel from 'models/Loading'
import { API } from 'services'
import { Manager } from 'shared/types'

class ManagerModel {
  managers: Manager[] = []
  loading: LoadingModel

  constructor() {
    makeAutoObservable(this)

    this.loading = new LoadingModel()
  }

  async fetch() {
    try {
      this.loading.begin()

      const data = await API.manager.get({})

      this.managers = data
    } catch (err: any) {
      console.log(err)
    } finally {
      this.loading.end()
    }
  }

  async create(manager: Manager) {
    try {
      this.loading.begin()

      await API.manager.create(manager)

      this.fetch()
    } catch (err: any) {
      console.log(err)
    } finally {
      this.loading.end()
    }
  }

  async update(manager: Manager, id: number) {
    try {
      this.loading.begin()

      await API.manager.update(manager, id)

      this.fetch()
    } catch (err: any) {
      console.log(err)
    } finally {
      this.loading.end()
    }
  }

  async delete(id: number) {
    try {
      this.loading.begin()

      await API.manager.deleteById(id)

      this.fetch()
    } catch (err: any) {
      console.log(err)
    } finally {
      this.loading.end()
    }
  }
}

const model = new ManagerModel()

export default model
