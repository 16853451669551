import { useState } from 'react'
import { Box, Button, Stack, Typography } from '@mui/material'
import { observer } from 'mobx-react-lite'

import { DeleteItem, Input } from 'shared/ui'
import { Manager } from 'shared/types'

import { ManagerModel } from '../../model'

function Form({ manager }: { manager: Manager }) {
  const [name, setName] = useState(manager.telegram_id)

  return (
    <Stack gap="5px">
      <Typography
        sx={{
          color: (theme) => theme.palette.grey[800],
          fontFamily: 'IBM Plex Sans',
          fontSize: '16px',
          fontWeight: 500
        }}
      >
        Введите telegram-id менеджера
      </Typography>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          gap: '30px'
        }}
      >
        <Input
          value={name}
          onChange={(e: any) => setName(e.target.value)}
          sx={{ fontFamily: 'Rubik, sans-serif !important' }}
        />
        <Button
          onClick={() => {
            if (manager.id) {
              ManagerModel.update({ telegram_id: name }, manager.id)
            }
          }}
          size="small"
          disabled={name === manager.telegram_id || !Boolean(name)}
          sx={{ p: '4px 32px', fontSize: '15px!important' }}
          variant="contained"
        >
          Сохранить
        </Button>
        <DeleteItem
          name="менеджера"
          onDelete={() => {
            if (manager.id) {
              ManagerModel.delete(manager.id)
            }
          }}
        />
      </Box>
    </Stack>
  )
}

export default observer(Form)
