import axios from 'axios'
import { getQueryParams } from 'shared/helpers'

import { Manager } from 'shared/types'

const ENDPOINT_BASE_ADMIN = '/api/v1/managers'
const { REACT_APP_BASE_URL } = process.env

export const get = async ({
  searchParams
}: {
  searchParams?: { [key: string]: string }
}): Promise<Manager[]> => {
  const config = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('ACCESS_TOKEN')}`
    }
  }

  const response = await axios.get<Manager[]>(
    `${REACT_APP_BASE_URL}${ENDPOINT_BASE_ADMIN}${getQueryParams({
      ...searchParams
    })}`,
    config
  )
  return response.data
}

export const create = async (manager: Manager) => {
  const config = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('ACCESS_TOKEN')}`
    }
  }

  await axios.post(`${REACT_APP_BASE_URL}${ENDPOINT_BASE_ADMIN}`, manager, config)
}

export const update = async (manager: Manager, id: number) => {
  const config = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('ACCESS_TOKEN')}`
    }
  }

  await axios.put(`${REACT_APP_BASE_URL}${ENDPOINT_BASE_ADMIN}/${id}`, manager, config)
}

export const deleteById = async (id: number) => {
  const config = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('ACCESS_TOKEN')}`
    }
  }

  await axios.delete(`${REACT_APP_BASE_URL}${ENDPOINT_BASE_ADMIN}/${id}`, config)
}
