import { useMemo, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { useSearchParams } from 'react-router-dom'
import { Box, Button, Grid, Paper, Stack, TableContainer } from '@mui/material'

import { PageHeader } from 'widgets'
import { getSearchParamsObj } from 'shared/helpers'
import { CommonTable, FormInput, Spinner } from 'shared/ui'

import { CitiesModel, getColumns } from './model'
import { Formik } from 'formik'

function Cities() {
  const [searchParams] = useSearchParams()

  useEffect(() => {
    CitiesModel.debounceFetch({ searchParams: getSearchParamsObj(searchParams) })
  }, [searchParams])

  const columns = useMemo(() => getColumns({ onDelete: (id) => CitiesModel.delete(id) }), [])

  return (
    <Stack spacing={2}>
      <PageHeader pageName="Города" />
      <Grid container direction="column">
        <Grid elevation={4} component={Paper} container direction="column">
          <Formik
            initialValues={{ name: '' }}
            onSubmit={(values, { resetForm }) => {
              CitiesModel.create({ name: values.name })
              resetForm()
            }}
          >
            {({ handleSubmit, values }) => (
              <form onSubmit={handleSubmit}>
                <Box sx={{ display: 'flex', gap: '20px', m: '40px 23px' }}>
                  <FormInput field="name" label="Название города" variant="outlined" />
                  <Button
                    type="submit"
                    size="small"
                    variant="contained"
                    disabled={Boolean(!values.name)}
                    fullWidth
                    sx={{ p: '4px 36px', fontSize: '14px' }}
                  >
                    Добавить
                  </Button>
                </Box>
              </form>
            )}
          </Formik>
          <Grid item>
            {CitiesModel.loading.has ? (
              <Spinner />
            ) : (
              <TableContainer component={Paper} sx={{ mb: '300px' }}>
                <CommonTable data={CitiesModel.cities} columns={columns} />
              </TableContainer>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Stack>
  )
}

export default observer(Cities)
