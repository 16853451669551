import { observer } from 'mobx-react-lite'
import { Grid, InputAdornment, Paper, TextField } from '@mui/material'
import { FiSearch } from 'react-icons/fi'

interface Props {
  // handleOpenFilter?: () => void
  // isShowFilter?: boolean

  inputValue: string
  onInputChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  inputPlaceholder: string

  // selectValue: string
  // onSelectChange: (e: React.ChangeEvent<HTMLSelectElement>) => void

  // sortOptions: { [key: string]: string }
  isTablePart?: boolean
  // children?: JSX.Element
}

function AsideFiltersBar({
  // handleOpenFilter,
  inputValue,
  onInputChange,
  inputPlaceholder: inputLabel,
  // selectValue,
  // onSelectChange,
  // sortOptions,
  isTablePart = false
}: // isShowFilter = true,
// children
Props) {
  return (
    <Grid
      component={Paper}
      item
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        p: 3,
        gap: 1,
        borderRadius: isTablePart ? '20px 20px 0 0' : 1
      }}
    >
      <TextField
        value={inputValue}
        onChange={onInputChange}
        fullWidth
        size="small"
        placeholder={inputLabel}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">{<FiSearch size="16px" />}</InputAdornment>
          )
        }}
      />
      {/* <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
        <Select
          value={selectValue}
          onChange={onSelectChange}
          label="actions.sortBy"
          options={sortOptions}
          sx={{ minWidth: '160px' }}
        />
        {isShowFilter && (
          <Button
            onClick={handleOpenFilter}
            sx={{ px: 1 }}
            size="small"
            color="info"
            endIcon={<BsFilter />}
          >
            Фильтр
          </Button>
        )}
        {children}
      </Box> */}
    </Grid>
  )
}

export default observer(AsideFiltersBar)
