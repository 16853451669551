import { useState, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { Box, Button, Paper, Stack, Typography } from '@mui/material'

import { PageHeader } from 'widgets'
import { Input, Spinner } from 'shared/ui'

import { ManagerModel } from './model'
import { Form } from './ui'

function Manager() {
  const [name, setName] = useState('')

  useEffect(() => {
    ManagerModel.fetch()
  }, [])

  return (
    <Stack>
      <PageHeader pageName="Менеджеры" />

      {ManagerModel.loading.has ? (
        <Spinner />
      ) : (
        <Stack component={Paper} elevation={3} sx={{ display: 'flex', p: 5 }}>
          <Stack sx={{ width: '850px', gap: '30px' }}>
            {ManagerModel.managers?.map((manager) => (
              <Form key={manager.id} manager={manager} />
            ))}

            <Stack gap="5px">
              <Typography
                sx={{
                  color: (theme) => theme.palette.grey[800],
                  fontFamily: 'IBM Plex Sans',
                  fontSize: '16px',
                  fontWeight: 500
                }}
              >
                Введите telegram-id менеджера
              </Typography>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  gap: '50px'
                }}
              >
                <Input
                  value={name}
                  onChange={(e: any) => setName(e.target.value)}
                  sx={{ fontFamily: 'Rubik, sans-serif !important' }}
                />
                <Button
                  onClick={() => {
                    ManagerModel.create({ telegram_id: name })
                    setName('')
                  }}
                  size="small"
                  disabled={!Boolean(name)}
                  variant="contained"
                  sx={{ p: '4px 32px' }}
                >
                  Сохранить
                </Button>
              </Box>
            </Stack>
          </Stack>
        </Stack>
      )}
    </Stack>
  )
}

export default observer(Manager)
