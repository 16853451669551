import { Outlet } from 'react-router-dom'
import { Box, Container } from '@mui/material'
import { observer } from 'mobx-react-lite'

import { NavBar } from 'widgets'

function Layout() {
  return (
    <Box sx={{ display: 'flex', minHeight: '100vh' }}>
      <NavBar />

      <Container maxWidth="xl" sx={{ py: '64px', height: '100%' }}>
        <Outlet />
      </Container>
    </Box>
  )
}

export default observer(Layout)
