import { useMemo, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import {
  Collapse,
  IconButton,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography
} from '@mui/material'
import { AiOutlineRight, AiOutlineDown } from 'react-icons/ai'

import { useRootStore } from 'stores'

interface Props {
  title: string
  to?: string
  collapsedItems?: { to: string; text: string; icon: JSX.Element }[]
}

const NavBarItem = ({ title, to = '', collapsedItems = [] }: Props) => {
  const navigate = useNavigate()
  const location = useLocation()

  const { authorization } = useRootStore()
  const [isOpen, setIsOpen] = useState(false)

  const onItemClick = () => {
    if (to === 'unauthorize') {
      authorization.unauthorize()
    } else if (to) {
      navigate(to)
    } else {
      setIsOpen((pre) => !pre)
    }
  }

  const isActive = useMemo(
    () =>
      to ? location.pathname === to : collapsedItems.find((item) => location.pathname === item.to),
    [to, location, collapsedItems]
  )

  return (
    <>
      <ListItemButton
        sx={
          isActive
            ? {
                py: 0.6,
                px: 1,
                height: 'fit-content',
                borderLeft: '0.2px solid #C7CFD6',
                borderRight: '0.2px solid #C7CFD6',
                background: '#FFF'
              }
            : {
                py: 0.6,
                px: 1,
                borderRadius: 1,
                height: 'fit-content'
              }
        }
        onClick={onItemClick}
      >
        <ListItemText>
          <Typography
            variant="body2"
            color={({ palette }) => palette.text.secondary}
            sx={{ ml: '50px' }}
          >
            {title}
          </Typography>
        </ListItemText>

        {!to && (
          <IconButton sx={{ color: ({ palette }) => palette.grey[600], fontSize: 12 }}>
            {isOpen ? <AiOutlineDown /> : <AiOutlineRight />}
          </IconButton>
        )}
      </ListItemButton>

      {!to && (
        <Collapse in={isOpen} timeout="auto" mountOnEnter unmountOnExit>
          <List component="div" disablePadding>
            {collapsedItems.map((item) => {
              return (
                <ListItemButton
                  component={Link}
                  to={item.to}
                  sx={{ p: 0.6, borderRadius: 1, pl: 4, height: 'fit-content!important' }}
                  key={item.to}
                >
                  <ListItemIcon
                    sx={{
                      color: ({ palette }) => palette.primary.light,
                      fontSize: 20
                    }}
                  >
                    {item.icon}
                  </ListItemIcon>
                  <Typography
                    variant="body2"
                    sx={{ color: ({ palette }) => palette.primary.light }}
                  >
                    {item.text}
                  </Typography>
                </ListItemButton>
              )
            })}
          </List>
        </Collapse>
      )}
    </>
  )
}

export default observer(NavBarItem)
