import axios from 'axios'
import { getQueryParams } from 'shared/helpers'

import { FAQ } from 'shared/types'

const ENDPOINT_BASE_ADMIN = '/api/v1/faq'
const { REACT_APP_BASE_URL } = process.env

export const get = async ({
  searchParams
}: {
  searchParams?: { [key: string]: string }
}): Promise<FAQ[]> => {
  const accessToken = localStorage.getItem('ACCESS_TOKEN')
  const config = {
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  }

  const response = await axios.get<FAQ[]>(
    `${REACT_APP_BASE_URL}${ENDPOINT_BASE_ADMIN}${getQueryParams({
      ...searchParams
    })}`,
    config
  )
  return response.data
}

export const create = async (faq: FAQ) => {
  const accessToken = localStorage.getItem('ACCESS_TOKEN')
  const config = {
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  }

  await axios.post(`${REACT_APP_BASE_URL}${ENDPOINT_BASE_ADMIN}`, faq, config)
}

export const update = async (faq: FAQ, id: number) => {
  const accessToken = localStorage.getItem('ACCESS_TOKEN')
  const config = {
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  }

  await axios.put(`${REACT_APP_BASE_URL}${ENDPOINT_BASE_ADMIN}/${id}`, faq, config)
}

export const deleteById = async (id: number) => {
  const accessToken = localStorage.getItem('ACCESS_TOKEN')
  const config = {
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  }

  await axios.delete(`${REACT_APP_BASE_URL}${ENDPOINT_BASE_ADMIN}/${id}`, config)
}
