import { debounce } from '@mui/material'
import { makeAutoObservable } from 'mobx'

import { API } from 'services'
import { Vacancy } from 'shared/types'
import LoadingModel from 'models/Loading'

class VacanciesModel {
  vacancies: Vacancy[] = []

  loading: LoadingModel

  constructor() {
    makeAutoObservable(this)

    this.loading = new LoadingModel()
  }

  debounceFetch = debounce(this.fetch, 500)

  async fetch() {
    try {
      this.loading.begin()

      const data = await API.vacancies.get({})

      this.vacancies = data
    } catch (err: any) {
      console.log(err)
    } finally {
      this.loading.reset()
    }
  }
}

const model = new VacanciesModel()

export default model
