import { useState } from 'react'
import { Box, Button, Typography } from '@mui/material'
import { observer } from 'mobx-react-lite'

import { DeleteItem, Input } from 'shared/ui'
import { FAQ } from 'shared/types'

import { FAQModel } from '../../model'

function Form({ faq, index }: { faq: FAQ; index: number }) {
  const [question, setQuestion] = useState(faq.question)
  const [answer, setAnswer] = useState(faq.answer)

  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Typography
        sx={{
          color: (theme) => theme.palette.grey[800],
          fontFamily: 'IBM Plex Sans',
          fontSize: '16px',
          fontWeight: 500,
          width: '140px',
          alignSelf: 'center'
        }}
      >
        {index + 1}
      </Typography>

      <Box width="400px">
        <Input
          value={question}
          onChange={(e: any) => setQuestion(e.target.value)}
          multiline
          sx={{ width: '360px' }}
          inputProps={{
            style: {
              height: '80px'
            }
          }}
        />
      </Box>

      <Box width="400px">
        <Input
          value={answer}
          onChange={(e: any) => setAnswer(e.target.value)}
          multiline
          sx={{ width: '360px' }}
          inputProps={{
            style: {
              height: '80px'
            }
          }}
        />
      </Box>

      <Button
        onClick={() => {
          if (faq.id) {
            FAQModel.update({ answer: answer, question: question }, faq.id)
          }
        }}
        disabled={
          !Boolean(question) ||
          !Boolean(answer) ||
          (question === faq.question && answer === faq.answer)
        }
        variant="contained"
        sx={{ p: '4px 32px', mr: '20px', fontSize: '16px' }}
      >
        Сохранить
      </Button>

      <DeleteItem
        name="менеджера"
        onDelete={() => {
          if (faq.id) {
            FAQModel.delete(faq.id)
          }
        }}
      />
    </Box>
  )
}

export default observer(Form)
