import { observer } from 'mobx-react-lite'
import { Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material'

import { TableColumn } from 'shared/types'

interface Props {
  data: { [key: string]: any }[]
  columns: TableColumn[]
}

const CommonTable = ({ data, columns }: Props) => {
  return (
    <Table>
      <TableHead>
        <TableRow>
          {columns.map((column) => (
            <TableCell
              key={column.key}
              align={column.align ?? 'left'}
              sx={{
                width: column.width,
                backgroundColor: 'white',
                p: '16px 50px',
                color: (theme) => theme.palette.primary.dark,
                fontWeight: 700,
                fontSize: '15px',
                fontFamily: 'Rubik',
                boxShadow: 'none',
                borderRadius: 0,
                borderTop: (theme) => `0.2px solid ${theme.palette.grey[400]}`,
                borderBottom: (theme) => `0.2px solid ${theme.palette.grey[400]}`
              }}
            >
              {column.title}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {data.map((row) => (
          <TableRow key={row.id}>
            {columns.map((column) => (
              <TableCell
                key={column.key}
                align={column.align ?? 'left'}
                sx={{
                  width: column.width,
                  p: '15px 50px',
                  borderTop: (theme) => `0.2px solid ${theme.palette.grey[400]}`,
                  borderBottom: (theme) => `0.2px solid ${theme.palette.grey[400]}`
                }}
              >
                {column.getValue ? column.getValue(row) : row[column.key]}
              </TableCell>
            ))}
          </TableRow>
        ))}
      </TableBody>
    </Table>
  )
}

export default observer(CommonTable)
