import { Box, IconButton, Typography } from '@mui/material'
import { IoCloseOutline } from 'react-icons/io5'

import { City, TableColumn } from 'shared/types'

export const getColumns = ({
  onDelete
}: {
  onDelete: (id: number) => Promise<void>
}): TableColumn[] => [
  {
    key: 'name',
    title: 'Название',
    width: '150px',
    getValue: (row: City) => (
      <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
        <Typography
          sx={{
            color: (theme) => theme.palette.info.main,
            fontSize: '15px',
            fontFamily: 'IBM Plex Sans',
            fontWeight: 500
          }}
        >
          {row.name}
        </Typography>
        <IconButton onClick={() => row.id && onDelete(row.id)}>
          <IoCloseOutline size={20} />
        </IconButton>
      </Box>
    )
  }
]
