import { observer } from 'mobx-react-lite'
import {
  Route,
  createBrowserRouter,
  createRoutesFromElements,
  RouterProvider,
  Navigate
} from 'react-router-dom'

import { Cities, Vacancies, CreateVacancy, Manager, FAQ, AboutCompany, VacancyInfo } from 'pages'
import { ROUTES } from 'shared/consts'

import { Layout } from './Layout'

const router = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route path={ROUTES.DEFAULT} element={<Layout />}>
        <Route path={ROUTES.CITIES} element={<Cities />} />

        <Route path={ROUTES.VACANCIES} element={<Vacancies />} />
        <Route path={ROUTES.VACANCIES_NEW} element={<CreateVacancy />} />
        <Route path={ROUTES.VACANCY} element={<VacancyInfo />} />

        <Route path={ROUTES.MANAGER} element={<Manager />} />

        <Route path={ROUTES.FAQ} element={<FAQ />} />

        <Route path={ROUTES.ABOUT_COMPANY} element={<AboutCompany />} />

        <Route path="/" element={<Navigate to={ROUTES.CITIES} replace />} />
        <Route path="*" element={<Navigate to={ROUTES.CITIES} replace />} />
      </Route>
    </>
  )
)

function Router() {
  return <RouterProvider router={router} />
}

export default observer(Router)
