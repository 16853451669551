import { observer } from 'mobx-react-lite'
import clsx from 'clsx'
import { useFormikContext } from 'formik'
import { Typography } from '@mui/material'

import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'

import './_textEditor.scss'

interface Props {
  field: string
  readonly?: boolean
}

function QuillField({ field, readonly = false }: Props) {
  const { touched, values, errors, setFieldValue } = useFormikContext<{
    [key: string]: any
  }>()

  return (
    <>
      <ReactQuill
        theme="snow"
        value={values[field]}
        onChange={(html: string) => {
          if (html === '<p><br></p>') {
            setFieldValue(field, '')
          } else {
            setFieldValue(field, html)
          }
        }}
        className={clsx('quill', touched[field] && Boolean(errors[field]) && 'error')}
        readOnly={readonly}
      />
      {touched[field] && (
        <Typography sx={{ ml: 2 }} color="error.main" variant="subtitle2">
          {errors[field]}
        </Typography>
      )}
    </>
  )
}

export default observer(QuillField)
