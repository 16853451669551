import * as aboutCompany from './aboutCompany'
import * as vacancies from './vacancies'
import * as contacts from './contacts'
import * as manager from './manager'
import * as cities from './cities'
import * as auth from './auth'
import * as faq from './faq'

const API = {
  auth,
  cities,
  vacancies,
  manager,
  faq,
  aboutCompany,
  contacts
}

export default API
