import { observer } from 'mobx-react-lite'
import { useFormikContext } from 'formik'
import {
  FormControl,
  FormHelperText,
  InputLabel,
  ListSubheader,
  MenuItem,
  Select
} from '@mui/material'

interface Props {
  field: string
  label: string
  options: { [key: string]: { isThisCategory: boolean; name: string } }
  multiple?: boolean
  readonly?: boolean
}

const FormSelect = ({ field, label, options, multiple = false, readonly = false }: Props) => {
  const { touched, values, errors, setFieldValue } = useFormikContext<{
    [key: string]: any
  }>()

  return (
    <FormControl fullWidth error={touched[field] && Boolean(errors[field])}>
      <InputLabel id={field}>{label}</InputLabel>
      <Select
        readOnly={readonly}
        multiple={multiple}
        labelId={field}
        id={field}
        value={values[field]}
        label={label}
        onChange={(e) => {
          setFieldValue(field, e.target.value)
        }}
      >
        {Object.entries(options).map(([key, item]) =>
          item.isThisCategory ? (
            <ListSubheader key={key}> {item.name}</ListSubheader>
          ) : (
            <MenuItem key={key} value={key}>
              {item.name}
            </MenuItem>
          )
        )}
      </Select>
      {touched[field] && <FormHelperText>{errors[field]}</FormHelperText>}
    </FormControl>
  )
}

export default observer(FormSelect)
