import { makeAutoObservable } from 'mobx'

import LoadingModel from 'models/Loading'
import { API } from 'services'
import { AboutCompany, Contact } from 'shared/types'

class AboutCompanyModel {
  abouts: AboutCompany[] = []
  contacts: Contact[] = []
  loading: LoadingModel

  constructor() {
    makeAutoObservable(this)

    this.loading = new LoadingModel()
  }

  async fetch() {
    try {
      this.loading.begin()

      const data = await API.aboutCompany.get({})
      const dataContacts = await API.contacts.get({})

      this.abouts = data
      this.contacts = dataContacts
    } catch (err: any) {
      console.log(err)
    } finally {
      this.loading.end()
    }
  }

  async create(about: AboutCompany) {
    try {
      this.loading.begin()

      let formData = new FormData()

      formData.append('description', about.description)

      formData.append('image', about.image)

      await API.aboutCompany.create(formData)

      await this.fetch()
    } catch (err: any) {
      console.log(err)
    } finally {
      this.loading.end()
    }
  }

  async delete(id: number) {
    try {
      this.loading.begin()

      await API.aboutCompany.deleteById(id)

      this.fetch()
    } catch (err: any) {
      console.log(err)
    } finally {
      this.loading.end()
    }
  }

  async createContact(contact: Contact) {
    try {
      this.loading.begin()

      let formData = new FormData()

      formData.append('description', contact.description)

      formData.append('image', contact.image)

      await API.contacts.create(formData)

      await this.fetch()
    } catch (err: any) {
      console.log(err)
    } finally {
      this.loading.end()
    }
  }

  async deleteContact(id: number) {
    try {
      this.loading.begin()

      await API.contacts.deleteById(id)

      this.fetch()
    } catch (err: any) {
      console.log(err)
    } finally {
      this.loading.end()
    }
  }
}

const model = new AboutCompanyModel()

export default model
