export const ROUTES = {
  HOME: '/home',

  DEFAULT: '/',

  PRODUCT: '/product/:id',
  PRODUCTS: '/products',
  PRODUCT_NEW: '/product/new',

  CATEGORIES: '/categories',
  CATEGORY_NEW: '/category/new',
  CATEGORY: '/category/:id',

  PORTFOLIO_INFO: '/portfolio/:id',
  PORTFOLIO: '/portfolio',
  PORTFOLIO_NEW: '/portfolio/new',

  CITIES: '/cities',

  VACANCIES: '/vacancies',
  VACANCIES_NEW: '/vacancies/new',
  VACANCY: '/vacancy/:id',

  MANAGER: '/manager',

  ABOUT_COMPANY: '/about-company',

  FAQ: '/faq'
}
