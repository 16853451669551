import { useState, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { Box, Button, Paper, Stack, Typography } from '@mui/material'

import { PageHeader } from 'widgets'
import { Input, Spinner } from 'shared/ui'

import { FAQModel } from './model'
import { Form } from './ui'

function FAQ() {
  const [question, setQuestion] = useState('')
  const [answer, setAnswer] = useState('')

  useEffect(() => {
    FAQModel.fetch()
  }, [])

  return (
    <Stack>
      <PageHeader pageName="FAQ" />

      {FAQModel.loading.has ? (
        <Spinner />
      ) : (
        <Stack component={Paper} elevation={3} sx={{ display: 'flex', p: 5 }}>
          <Stack sx={{ gap: '16px' }}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Typography
                sx={{
                  color: (theme) => theme.palette.grey[800],
                  fontFamily: 'IBM Plex Sans',
                  fontSize: '16px',
                  fontWeight: 500,
                  width: '140px'
                }}
              >
                Номер
              </Typography>

              <Typography
                sx={{
                  color: (theme) => theme.palette.grey[800],
                  fontFamily: 'IBM Plex Sans',
                  fontSize: '16px',
                  fontWeight: 500,
                  width: '400px'
                }}
              >
                Вопрос
              </Typography>

              <Typography
                sx={{
                  color: (theme) => theme.palette.grey[800],
                  fontFamily: 'IBM Plex Sans',
                  fontSize: '16px',
                  fontWeight: 500,
                  width: '400px'
                }}
              >
                Ответ
              </Typography>
            </Box>

            <Stack gap="30px">
              {FAQModel.faqs.map((faq, index) => (
                <Form key={faq.id} faq={faq} index={index} />
              ))}
            </Stack>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Typography
                sx={{
                  color: (theme) => theme.palette.grey[800],
                  fontFamily: 'IBM Plex Sans',
                  fontSize: '16px',
                  fontWeight: 500,
                  width: '140px',
                  alignSelf: 'center'
                }}
              >
                {FAQModel.faqs.length + 1}
              </Typography>

              <Box width="400px">
                <Input
                  value={question}
                  onChange={(e: any) => setQuestion(e.target.value)}
                  multiline
                  sx={{ width: '360px' }}
                  inputProps={{
                    style: {
                      height: '80px'
                    }
                  }}
                />
              </Box>

              <Box width="400px">
                <Input
                  value={answer}
                  onChange={(e: any) => setAnswer(e.target.value)}
                  multiline
                  sx={{ width: '360px' }}
                  inputProps={{
                    style: {
                      height: '80px'
                    }
                  }}
                />
              </Box>

              <Button
                onClick={() => {
                  FAQModel.create({ answer: answer, question: question })
                  setQuestion('')
                  setAnswer('')
                }}
                size="small"
                disabled={!Boolean(question) || !Boolean(answer)}
                variant="contained"
                sx={{ p: '4px 32px' }}
              >
                Сохранить
              </Button>
            </Box>
          </Stack>
        </Stack>
      )}
    </Stack>
  )
}

export default observer(FAQ)
