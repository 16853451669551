import { ReactNode } from 'react'
import { observer } from 'mobx-react-lite'
import { Box, Modal as MUiModal, Paper } from '@mui/material'

interface Props {
  open: boolean
  handleClose: () => void
  children: ReactNode
  closable?: boolean
  footer?: ReactNode
}

const Modal = ({ open, handleClose, children, closable = true, footer }: Props) => {
  return (
    <MUiModal
      open={open}
      onClose={() => {
        if (closable) {
          handleClose()
        }
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          bgcolor: 'background.paper',
          boxShadow: 24,
          minWidth: 300,
          p: '40px 68px',
          borderRadius: '10px',
          border: 'none'
        }}
      >
        {children}

        {footer && (
          <Paper
            sx={{
              display: 'flex',
              justifyContent: 'center',
              p: 2,
              borderRadius: 2,
              borderTopLeftRadius: 0,
              borderTopRightRadius: 0,
              backgroundColor: 'background.default'
            }}
          >
            {footer}
          </Paper>
        )}
      </Box>
    </MUiModal>
  )
}

export default observer(Modal)
