const lightTheme = {
  palette: {
    action: {
      active: '#6B7280',
      focus: 'rgba(55, 65, 81, 0.12)',
      hover: 'rgba(55, 65, 81, 0.04)',
      selected: 'rgba(55, 65, 81, 0.08)',
      disabledBackground: 'rgba(55, 65, 81, 0.12)',
      disabled: 'rgba(55, 65, 81, 0.26)'
    },
    background: {
      default: '#EFF3FE',
      paper: '#FFF',
      rare: '#fff',
      header: 'rgba(255, 255, 255, 0.8)'
    },
    primary: {
      main: '#596778',
      light: '#3C29A3',
      dark: '#475F7B',
      contrastText: '#FFFFFF'
    },
    secondary: {
      main: '#596778',
      light: '#3FC79A',
      dark: '#0B815A',
      contrastText: '#FFFFFF'
    },
    success: {
      main: '#14B8A6',
      light: '#43C6B7',
      dark: '#0E8074',
      contrastText: '#FFFFFF'
    },
    info: {
      main: '#5A8DEE',
      light: '#64B6F7',
      dark: '#0B79D0',
      contrastText: '#FFFFFF'
    },
    warning: {
      main: '#FFB020',
      light: '#FFBF4C',
      dark: '#B27B16',
      contrastText: '#FFFFFF'
    },
    error: {
      main: '#D14343',
      light: '#DA6868',
      dark: '#922E2E',
      contrastText: '#FFFFFF'
    },
    text: {
      primary: '#111927',
      secondary: '#475F7B',
      disabled: 'rgba(55, 65, 81, 0.48)'
    },
    grey: {
      100: '#F3F4F6',
      200: '#E5E7EB',
      300: '#D1D5DB',
      400: '#C7CFD6',
      500: '#6c737f',
      600: '#4B5563',
      700: 'rgba(47, 46, 47, 0.30);',
      800: '#454545',
      900: '#111827'
    },
    divider: 'rgb(45, 55, 72)'
  },
  components: {
    MuiInput: {
      input: {
        '&::placeholder': {
          color: 'gray'
        }
      },
      styleOverrides: {
        underline: {
          '&:before': {
            borderBottomColor: 'rgba(60, 41, 163, 0.40)'
          },
          '&:after': {
            borderBottomColor: 'rgba(60, 41, 163, 0.40)'
          },
          '&:hover:not(.Mui-disabled)': {
            '&:before': {
              borderBottomColor: 'rgba(60, 41, 163, 0.40)'
            }
          }
        }
      }
    },
    MuiButton: {
      root: {
        textTransform: 'none'
      },
      styleOverrides: {
        contained: {
          backgroundColor: '#0079AE',
          color: '#F5F8FE',
          boxShadow: 'none',
          width: 'fit-content',
          fontSize: '20px',
          padding: '15px 25px',
          borderRadius: '4px',
          fontWeight: 400,
          fontFamily: 'Evolventa',
          '&:hover': {
            backgroundColor: '#0079AE'
          }
        },
        outlined: {
          color: '#3C29A3',
          boxShadow: 'none',
          width: 'fit-content',
          fontSize: '20px',
          borderRadius: '14px',
          fontWeight: 400,
          fontFamily: 'Evolventa',
          '&:hover': {
            border: '1px solid #3C29A3',
            opacity: '0.8'
          }
        },
        outlinedError: {
          color: '#E31837',
          border: '1px solid #E31837',
          '&:hover': {
            border: '1px solid #E31837',
            opacity: '0.8'
          }
        },
        containedError: {
          padding: '15px 25px',
          color: '#fff',
          backgroundColor: '#E31837',
          fontSize: '16px',
          '&:hover': {
            color: '#fff',
            backgroundColor: '#E31837',
            opacity: '0.8'
          }
        },
        text: {
          fontSize: '16px'
        }
      }
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          '& .MuiInput-underline:before': {
            borderBottom: 'none' // Убрать полосу под TextField
          }
        },

        h3: {
          fontSize: '40px',
          color: '#2F2E2F',
          fontFamily: 'Evolventa'
        }
      }
    },

    MuiOutlinedInput: {
      styleOverrides: {
        notchedOutline: {
          borderColor: '#C7CFD6',
          color: 'black'
        }
      }
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          '&.Mui-disabled': {
            '& .MuiOutlinedInput-root': {
              '& .MuiOutlinedInput-notchedOutline': {
                color: 'black'
              },
              '&:hover .MuiOutlinedInput-notchedOutline': {
                color: 'black'
              }
            },
            '& .MuiInputBase-input': {
              color: 'black'
            }
          }
        }
      },
      defaultProps: {
        inputProps: {
          style: {
            fontFamily: 'Rubik, sans-serif',
            color: '#2F2E2F'
          }
        }
      }
    },
    MuiTableHead: {
      styleOverrides: {
        root: {
          backgroundColor: 'rgb(248, 249, 250)',
          '.MuiTableCell-root': {
            color: 'rgb(47, 55, 70)'
          }
        }
      }
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          borderBottom: '1px solid rgb(242, 244, 247)'
        }
      }
    },
    MuiAlert: {
      styleOverrides: {
        standardError: {
          backgroundColor: 'rgb(254, 252, 252)',
          color: 'rgb(101, 91, 90)',
          fontSize: 16
        }
      }
    },
    MuiLinearProgress: {
      styleOverrides: {
        root: {
          backgroundColor: 'rgb(195, 196, 249)',
          '& .MuiLinearProgress-barColorPrimary': {
            backgroundColor: 'rgb(99, 102, 241)'
          }
        }
      }
    },
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          scrollbarColor: 'rgba(55, 65, 81, 0.04) #FFF',
          '&::-webkit-scrollbar, & *::-webkit-scrollbar': {
            backgroundColor: '#FFF'
          },
          '&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb': {
            borderRadius: 8,
            backgroundColor: 'rgba(55, 65, 81, 0.04)',
            minHeight: 24,
            border: '3px solid rgba(55, 65, 81, 0.04)'
          },
          '&::-webkit-scrollbar-thumb:focus, & *::-webkit-scrollbar-thumb:focus': {
            backgroundColor: 'rgba(17, 25, 39, 0.12)'
          },
          '&::-webkit-scrollbar-thumb:active, & *::-webkit-scrollbar-thumb:active': {
            backgroundColor: 'rgba(17, 25, 39, 0.12)'
          },
          '&::-webkit-scrollbar-thumb:hover, & *::-webkit-scrollbar-thumb:hover': {
            backgroundColor: 'rgba(17, 25, 39, 0.12)'
          },
          '&::-webkit-scrollbar-corner, & *::-webkit-scrollbar-corner': {
            backgroundColor: 'rgba(17, 25, 39, 0.12)'
          }
        }
      }
    }
  }
}

export default lightTheme
