import { InputAdornment, TextField } from '@mui/material'
import { observer } from 'mobx-react-lite'

interface Props {
  placeholder?: string
  size?: 'small' | 'medium' | undefined
  icon?: JSX.Element
  fullWidth?: boolean
  variant?: 'standard' | 'filled' | 'outlined'
}

const Input = (props: Props & any) => {
  const { placeholder = '', size = 'small', icon, fullWidth = true, variant = 'outlined' } = props

  return (
    <TextField
      {...props}
      fullWidth={fullWidth}
      placeholder={placeholder}
      label={placeholder}
      variant={variant}
      size={size}
      InputProps={
        icon && {
          startAdornment: <InputAdornment position="start">{icon}</InputAdornment>
        }
      }
    />
  )
}

export default observer(Input)
