import axios from 'axios'

const ENDPOINT_BASE = '/login'
const { REACT_APP_BASE_URL } = process.env

export const signIn = async (login: string, password: string): Promise<{ token: string }> => {
  const response = await axios.post<{ token: string }>(`${REACT_APP_BASE_URL}${ENDPOINT_BASE}`, {
    login,
    password
  })
  return response.data
}
