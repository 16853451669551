import { observer } from 'mobx-react-lite'
import { Box, Button, Stack, Typography } from '@mui/material'

import { Modal } from 'shared/ui'

interface Props {
  open: boolean
  handleClose: () => void
  onDelete: () => void
}

function ConfirmDialog({ open, handleClose, onDelete }: Props) {
  const DeleteItem = () => {
    onDelete()
    handleClose()
  }

  return (
    <Modal open={open} handleClose={handleClose}>
      <Stack sx={{ mt: '50px' }}>
        <Typography variant="body2" sx={{ fontSize: '18px', color: 'rgba(47, 46, 47, 0.80);' }}>
          Вы действительно хотите удалить элемент?
        </Typography>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            gap: '30px',
            mt: '30px'
          }}
        >
          <Button
            onClick={DeleteItem}
            variant="contained"
            sx={{ p: '10px 30px', fontSize: '20px', borderRadius: '14px' }}
          >
            Удалить
          </Button>
          <Button
            onClick={handleClose}
            variant="outlined"
            sx={{
              p: '10px 30px',
              fontSize: '20px',
              borderRadius: '14px',
              color: (theme) => theme.palette.primary.light,
              border: (theme) => `1px solid ${theme.palette.primary.light}`
            }}
          >
            Отмена
          </Button>
        </Box>
      </Stack>
    </Modal>
  )
}

export default observer(ConfirmDialog)
