import * as Yup from 'yup'

import { MAX_IMAGE_SIZE, SUPPORTED_IMAGE_FORMATS } from 'shared/consts'

export const required = () => Yup.string().required('Поле должно быть заполнено')

export const commonStringValidation = (minSymbols: number = 3) =>
  Yup.string()
    .min(minSymbols, `Длина поля должна состоять минимум из ${minSymbols} символов`)
    .required('Поле должно быть заполнено')

export const commonNumberRangeValidation = ({
  min = Infinity,
  max = Infinity
}: {
  min?: number
  max?: number
}) =>
  Yup.number()
    .min(min, `Минимум числа ${min}`)
    .max(max, `Максимум числа ${max}`)
    .required('Поле должно быть заполнено')

export const emailValidation = () =>
  Yup.string().email('Введите корректный email адрес').required('Обязательное поле')

export const passwordValidation = () =>
  Yup.string().min(5, 'Пароль должен быть больше 8 символов').required('Обязательное поле')

export const fileValidation = () =>
  Yup.mixed()
    .nullable()
    .test('Размер', 'Размер изображения не должен превышать 10MB', (value: any) =>
      value === null ? true : value?.size <= MAX_IMAGE_SIZE
    )
    .test('Файл', 'Неверный тип файла', (value: any) =>
      value === null ? true : SUPPORTED_IMAGE_FORMATS.includes(value?.type)
    )

export const fileValidationRequired = () =>
  Yup.mixed()
    .required('Поле должно быть заполнено')
    .test(
      'Размер',
      'Размер изображения не должен превышать 10MB',
      (value: any) => value?.size <= MAX_IMAGE_SIZE
    )
    .test('Файл', 'Неверный тип файла', (value: any) =>
      SUPPORTED_IMAGE_FORMATS.includes(value?.type)
    )
