import { makeAutoObservable } from 'mobx'
import { toast } from 'react-toastify'

import LoadingModel from 'models/Loading'
import { API } from 'services'
import { FAQ } from 'shared/types'

class FAQModel {
  faqs: FAQ[] = []
  loading: LoadingModel

  constructor() {
    makeAutoObservable(this)

    this.loading = new LoadingModel()
  }

  async fetch() {
    try {
      this.loading.begin()

      const data = await API.faq.get({})

      this.faqs = data
    } catch (err: any) {
      console.log(err)
    } finally {
      this.loading.end()
    }
  }

  async create(faq: FAQ) {
    try {
      this.loading.begin()

      await API.faq.create(faq)

      this.fetch()
    } catch (err: any) {
      if (err.message === 'Request failed with status code 422') {
        toast.error('Вопрос и ответ не могут повторяться!')
      }
      console.log(err.message)
    } finally {
      this.loading.end()
    }
  }

  async update(faq: FAQ, id: number) {
    try {
      this.loading.begin()

      await API.faq.update(faq, id)

      this.fetch()
    } catch (err: any) {
      console.log(err)
    } finally {
      this.loading.end()
    }
  }

  async delete(id: number) {
    try {
      this.loading.begin()

      await API.faq.deleteById(id)

      this.fetch()
    } catch (err: any) {
      console.log(err)
    } finally {
      this.loading.end()
    }
  }
}

const model = new FAQModel()

export default model
