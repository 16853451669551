import { observer } from 'mobx-react-lite'
import * as yup from 'yup'
import { Formik } from 'formik'
import { Box, Button, Container, Paper, Stack, Typography } from '@mui/material'

import { useRootStore } from 'stores'
import { FormInput, Spinner } from 'shared/ui'
import { commonStringValidation, passwordValidation } from 'shared/validations'
import logo from 'shared/assets/icons/logo.svg'
import { Helmet } from 'react-helmet'

function SignIn() {
  const { authorization } = useRootStore()

  const validationSchema = yup.object().shape({
    login: commonStringValidation(),
    password: passwordValidation()
  })

  return (
    <>
      <Helmet>
        <title>Вход</title>
        <meta name="description" content="Вход" />
      </Helmet>

      <Container
        maxWidth="lg"
        sx={{ display: 'flex', justifyContent: 'center', height: '100vh', width: '100vw' }}
      >
        {authorization.rootStore.loading.has ? (
          <Spinner />
        ) : (
          <Paper
            component={Stack}
            sx={{
              width: '100%',
              alignSelf: 'center',
              p: '100px 70px',
              gap: '30px',
              borderRadius: '4px'
            }}
          >
            <Box sx={{ width: '301px', height: '67px' }}>
              <img src={logo} alt="Logo" />
            </Box>
            <Formik
              initialValues={{
                login: '',
                password: ''
              }}
              validationSchema={validationSchema}
              onSubmit={(values) => {
                authorization.signIn(values)
              }}
            >
              {({ handleSubmit }) => (
                <form onSubmit={handleSubmit}>
                  <Stack spacing="30px" sx={{ maxWidth: '465px' }}>
                    <Stack>
                      <Typography
                        variant="body2"
                        sx={{ color: (theme) => theme.palette.primary.main }}
                      >
                        Логин
                      </Typography>
                      <FormInput field="login" type="username" size="medium" variant="outlined" />
                    </Stack>
                    <Stack>
                      <Typography
                        variant="body2"
                        sx={{ color: (theme) => theme.palette.primary.main }}
                      >
                        Пароль
                      </Typography>
                      <FormInput
                        field="password"
                        type="password"
                        size="medium"
                        variant="outlined"
                      />
                    </Stack>
                    <Button
                      type="submit"
                      size="small"
                      variant="contained"
                      fullWidth
                      sx={{ width: '100%', fontSize: '14px' }}
                    >
                      Войти
                    </Button>
                  </Stack>
                </form>
              )}
            </Formik>
          </Paper>
        )}
      </Container>
    </>
  )
}

export default observer(SignIn)
