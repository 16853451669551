import { useEffect, useState } from 'react'
import * as yup from 'yup'
import { Formik } from 'formik'
import { observer } from 'mobx-react-lite'
import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Stack,
  Typography
} from '@mui/material'
import { useNavigate, useParams } from 'react-router-dom'

import { City } from 'shared/types'
import { PageHeader } from 'widgets'
import { DeleteItem, FormInput, Spinner, UploadImageField } from 'shared/ui'
import { commonStringValidation, fileValidation } from 'shared/validations'

import { VacancyInfoModel } from './model'
import { ROUTES } from 'shared/consts'

function VacancyInfo() {
  const { id } = useParams()
  const navigate = useNavigate()
  const [isEdit, setIsEdit] = useState(false)

  useEffect(() => {
    VacancyInfoModel.fetch({ id: Number(id) })
  }, [id])

  const validationSchema = yup.object().shape({
    name: commonStringValidation(),
    description: commonStringValidation(),
    advantages: commonStringValidation(),
    payment: commonStringValidation(),
    cities: yup
      .array()
      .min(1, 'Должен быть выбран минимум 1 город')
      .required('Должен быть выбран минимум 1 город'),
    image: fileValidation()
  })

  return (
    <Stack>
      {VacancyInfoModel.loading.has ? (
        <Spinner />
      ) : (
        <Formik
          initialValues={{
            name: VacancyInfoModel.name,
            description: VacancyInfoModel.description,
            advantages: VacancyInfoModel.advantages,
            payment: VacancyInfoModel.payment,
            cities: VacancyInfoModel.cities.map((vacancy) => Number(vacancy.id)),
            image_url: VacancyInfoModel.image_url,
            image: null
          }}
          validationSchema={validationSchema}
          onSubmit={async (values) => {
            await VacancyInfoModel.update({ vacancy: values, id: Number(id) })

            setIsEdit(false)
          }}
        >
          {({ handleSubmit, touched, errors, values, setFieldValue }) => (
            <form onSubmit={handleSubmit}>
              <PageHeader pageName="Вакансия">
                {isEdit ? (
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      gap: '20px'
                    }}
                  >
                    {console.log(values.cities)}
                    <DeleteItem
                      name="портфолио"
                      onDelete={async () => {
                        await VacancyInfoModel.delete(Number(id))
                        navigate(ROUTES.VACANCIES)
                      }}
                    />
                    <Button
                      type="submit"
                      variant="contained"
                      size="small"
                      sx={{
                        height: 'fit-content',
                        p: '4px 32px',
                        fontSize: '16px'
                      }}
                    >
                      Сохранить
                    </Button>
                  </Box>
                ) : (
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: '20px' }}>
                    <DeleteItem
                      name="портфолио"
                      onDelete={async () => {
                        await VacancyInfoModel.delete(Number(id))
                        navigate(ROUTES.VACANCIES)
                      }}
                    />
                    <Button
                      type="button"
                      onClick={(e) => {
                        e.preventDefault()
                        setIsEdit(true)
                      }}
                      variant="contained"
                      sx={{ height: 'fit-content', p: '4px 32px', fontSize: '16px' }}
                    >
                      Редактировать
                    </Button>
                  </Box>
                )}
              </PageHeader>

              <Stack component={Paper} elevation={3} sx={{ display: 'flex', p: 5 }}>
                <Stack sx={{ width: '850px', gap: '50px' }}>
                  <Stack sx={{ gap: '20px' }}>
                    <Typography
                      variant="h6"
                      sx={{
                        color: (theme) => theme.palette.grey[800],
                        fontSize: '20px',
                        fontWeight: 700,
                        fontFamily: 'Poppins'
                      }}
                    >
                      Название
                    </Typography>

                    <Box sx={{ ml: '20px' }}>
                      <FormInput
                        field="name"
                        label="Название"
                        variant="outlined"
                        readonly={!isEdit}
                      />
                    </Box>
                  </Stack>

                  <Stack sx={{ gap: '20px' }}>
                    <Typography
                      variant="h6"
                      sx={{
                        color: (theme) => theme.palette.grey[800],
                        fontSize: '20px',
                        fontWeight: 700,
                        fontFamily: 'Poppins'
                      }}
                    >
                      Мы предлагаем
                    </Typography>

                    <Box sx={{ ml: '20px' }}>
                      <FormInput
                        field="description"
                        label="Мы предлагаем"
                        variant="outlined"
                        multiline
                        readonly={!isEdit}
                      />
                    </Box>
                  </Stack>

                  <Stack sx={{ gap: '20px' }}>
                    <Typography
                      variant="h6"
                      sx={{
                        color: (theme) => theme.palette.grey[800],
                        fontSize: '20px',
                        fontWeight: 700,
                        fontFamily: 'Poppins'
                      }}
                    >
                      Что надо делать
                    </Typography>

                    <Box sx={{ ml: '20px' }}>
                      <FormInput
                        field="advantages"
                        label="Что надо делать"
                        variant="outlined"
                        multiline
                        readonly={!isEdit}
                      />
                    </Box>
                  </Stack>

                  <Stack sx={{ gap: '20px' }}>
                    <Typography
                      variant="h6"
                      sx={{
                        color: (theme) => theme.palette.grey[800],
                        fontSize: '20px',
                        fontWeight: 700,
                        fontFamily: 'Poppins'
                      }}
                    >
                      Оплата
                    </Typography>

                    <Box sx={{ ml: '20px' }}>
                      <FormInput
                        field="payment"
                        label="Оплата"
                        variant="outlined"
                        multiline
                        readonly={!isEdit}
                      />
                    </Box>
                  </Stack>

                  <Stack sx={{ gap: '20px' }}>
                    <Typography
                      variant="h6"
                      sx={{
                        color: (theme) => theme.palette.grey[800],
                        fontSize: '20px',
                        fontWeight: 700,
                        fontFamily: 'Poppins'
                      }}
                    >
                      Город
                    </Typography>

                    <Box sx={{ ml: '20px' }}>
                      <FormControl fullWidth error={touched['cities'] && Boolean(errors['cities'])}>
                        <InputLabel id={'cities'}>Город</InputLabel>

                        <Select
                          readOnly={!isEdit}
                          labelId="cities"
                          id="cities"
                          name="cities"
                          label="Город"
                          multiple
                          value={values['cities']}
                          onChange={(e) => {
                            setFieldValue('cities', e.target.value)
                          }}
                        >
                          {VacancyInfoModel?.citiesNames?.map((city: City) => (
                            <MenuItem key={city.id} value={city.id}>
                              {city.name}
                            </MenuItem>
                          ))}
                        </Select>
                        {touched['cities'] && <FormHelperText>{errors['cities']}</FormHelperText>}
                      </FormControl>
                    </Box>
                  </Stack>

                  <Stack sx={{ gap: '20px' }}>
                    <Typography
                      variant="h6"
                      sx={{
                        color: (theme) => theme.palette.grey[800],
                        fontSize: '20px',
                        fontWeight: 700,
                        fontFamily: 'Poppins'
                      }}
                    >
                      Картинка
                    </Typography>

                    <Box sx={{ ml: '20px' }}>
                      <UploadImageField
                        readonly={!isEdit}
                        field="image"
                        imageUrl={values.image_url}
                        imgSx={{ borderRadius: 0 }}
                      />
                    </Box>
                  </Stack>
                </Stack>
              </Stack>
            </form>
          )}
        </Formik>
      )}
    </Stack>
  )
}

export default observer(VacancyInfo)
