import { Helmet } from 'react-helmet'
import { Grid, Typography } from '@mui/material'

interface Props {
  pageName: string
  children?: JSX.Element | null
}

const PageHeader = ({ pageName, children }: Props) => {
  return (
    <>
      <Helmet>
        <title>{pageName}</title>
        <meta name="description" content={pageName} />
      </Helmet>

      <Grid container spacing={2} sx={{ justifyContent: 'space-between' }}>
        <Grid item sx={{ mb: '8px' }}>
          <Typography variant="h3" sx={{ color: (theme) => theme.palette.primary.dark }}>
            {pageName}
          </Typography>
        </Grid>
        {children}
      </Grid>
    </>
  )
}

export default PageHeader
