import { makeAutoObservable } from 'mobx'

import LoadingModel from 'models/Loading'
import { City } from 'shared/types'
import { API } from 'services'

class CreateVacancyModel {
  cities: City[] = []
  loading: LoadingModel

  constructor() {
    makeAutoObservable(this)

    this.loading = new LoadingModel()
  }

  async fetch() {
    try {
      this.loading.begin()

      const data = await API.cities.get({})

      this.cities = data
    } catch (err: any) {
      console.log(err)
    } finally {
      this.loading.end()
    }
  }

  async create({
    vacancy
  }: {
    vacancy: {
      name: string
      description: string
      advantages: string
      payment: string
      cities: number[]
      image: any
    }
  }) {
    try {
      this.loading.begin()

      let formData = new FormData()

      formData.append('name', vacancy.name)

      formData.append('advantages', vacancy.advantages)

      formData.append('description', vacancy.description)

      formData.append('payment', vacancy.payment)

      formData.append('cities', vacancy.cities.join(','))

      formData.append('image', vacancy.image)

      await API.vacancies.create(formData)
    } catch (err: any) {
      console.log(err)
    } finally {
      this.loading.end()
    }
  }
}

const model = new CreateVacancyModel()

export default model
