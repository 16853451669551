import { useMemo } from 'react'
import { Box, List } from '@mui/material'

import logo from 'shared/assets/icons/logo.svg'

import { NavBarItem } from './ui'
import { config } from './config'
import s from './Styles.module.scss'

function NavBar() {
  const navBarConfig = useMemo(() => config, [])

  return (
    <Box sx={{ width: '260px', mt: '8px', borderRight: '1px solid #D8D8D8 ' }}>
      <img src={logo} alt="Logo" className={s.logo} />
      <List sx={{ display: 'flex', flexDirection: 'column', mt: '5px' }}>
        {navBarConfig.map((item) => (
          <NavBarItem title={item.title} to={item?.to} key={item.title} />
        ))}
      </List>
    </Box>
  )
}

export default NavBar
