import { Typography } from '@mui/material'
import { Link, generatePath } from 'react-router-dom'

import { ROUTES } from 'shared/consts'

import { TableColumn, Vacancy } from 'shared/types'

export const getColumns = (): TableColumn[] => [
  {
    key: 'id',
    title: 'Позиция',
    width: '20%'
  },
  {
    key: 'name',
    title: 'Название',
    width: '25%',
    getValue: (row: Vacancy) => (
      <Typography
        component={Link}
        to={generatePath(ROUTES.VACANCY, { id: String(row.id) })}
        sx={{
          color: (theme) => theme.palette.info.main,
          fontSize: '15px',
          fontFamily: 'IBM Plex Sans',
          fontWeight: 500,
          textDecoration: 'none'
        }}
      >
        {row.name}
      </Typography>
    )
  },
  {
    key: 'city_id',
    title: 'Город',
    getValue: (row: Vacancy) => (
      <Typography
        sx={{
          color: (theme) => theme.palette.primary.main,
          fontSize: '15px',
          fontFamily: 'IBM Plex Sans',
          fontWeight: 500
        }}
      >
        {row.cities.map((city, id) => {
          if (id === row.cities.length - 1) {
            return city.name
          } else {
            return `${city.name}, `
          }
        })}
      </Typography>
    )
  }
]
