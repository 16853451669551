import { debounce } from '@mui/material'
import { makeAutoObservable } from 'mobx'

import { API } from 'services'
import { City } from 'shared/types'
import PaginationModel from 'models/Pagination'
import LoadingModel from 'models/Loading'

class CitiesModel {
  cities: City[] = []

  pagination: PaginationModel
  loading: LoadingModel

  constructor() {
    makeAutoObservable(this)

    this.pagination = new PaginationModel()
    this.loading = new LoadingModel()
  }

  debounceFetch = debounce(this.fetch, 500)

  async fetch({ searchParams }: { searchParams?: { [key: string]: string } }) {
    try {
      this.loading.begin()

      const data = await API.cities.get({ searchParams: searchParams })

      this.cities = data
    } catch (err: any) {
      console.log(err)
    } finally {
      this.loading.reset()
    }
  }

  async create(city: City) {
    try {
      this.loading.begin()

      await API.cities.create(city)

      this.fetch({})
    } catch (err) {
      console.log(err)
    } finally {
      this.loading.reset()
    }
  }

  async delete(id: number) {
    try {
      this.loading.begin()

      await API.cities.deleteById(id)

      this.fetch({})
    } catch (err: any) {
      console.log(err)
    } finally {
      this.loading.end()
    }
  }
}

const model = new CitiesModel()

export default model
