import { useEffect } from 'react'
import * as yup from 'yup'
import { Formik } from 'formik'
import { observer } from 'mobx-react-lite'
import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Stack,
  Typography
} from '@mui/material'
import { useNavigate } from 'react-router-dom'

import { City } from 'shared/types'
import { PageHeader } from 'widgets'
import { ROUTES } from 'shared/consts'
import { FormInput, Spinner, UploadImageField } from 'shared/ui'
import { commonStringValidation, fileValidationRequired } from 'shared/validations'

import { CreateVacancyModel } from './model'

function CreateVacancy() {
  const navigate = useNavigate()

  useEffect(() => {
    CreateVacancyModel.fetch()
  }, [])

  const validationSchema = yup.object().shape({
    name: commonStringValidation(),
    description: commonStringValidation(),
    advantages: commonStringValidation(),
    payment: commonStringValidation(),
    cities: yup
      .array()
      .min(1, 'Должен быть выбран минимум 1 город')
      .required('Должен быть выбран минимум 1 город'),
    image: fileValidationRequired()
  })

  return (
    <Stack>
      {CreateVacancyModel.loading.has ? (
        <Spinner />
      ) : (
        <Formik
          initialValues={{
            name: '',
            description: '',
            advantages: '',
            payment: '',
            cities: [],
            image: null
          }}
          validationSchema={validationSchema}
          onSubmit={async (values) => {
            await CreateVacancyModel.create({ vacancy: values })

            navigate(ROUTES.VACANCIES)
          }}
        >
          {({ handleSubmit, touched, errors, values, setFieldValue }) => (
            <form onSubmit={handleSubmit}>
              <PageHeader pageName="Новая вакансия">
                <Button
                  type="submit"
                  variant="contained"
                  sx={{ height: 'fit-content', mt: '16px', p: '4px 16px' }}
                >
                  Добавить
                </Button>
              </PageHeader>

              <Stack component={Paper} elevation={3} sx={{ display: 'flex', p: 5 }}>
                <Stack sx={{ width: '850px', gap: '50px' }}>
                  <Stack sx={{ gap: '20px' }}>
                    <Typography
                      variant="h6"
                      sx={{
                        color: (theme) => theme.palette.grey[800],
                        fontSize: '20px',
                        fontWeight: 700,
                        fontFamily: 'Poppins'
                      }}
                    >
                      Название
                    </Typography>

                    <Box sx={{ ml: '20px' }}>
                      <FormInput field="name" label="Название" variant="outlined" />
                    </Box>
                  </Stack>

                  <Stack sx={{ gap: '20px' }}>
                    <Typography
                      variant="h6"
                      sx={{
                        color: (theme) => theme.palette.grey[800],
                        fontSize: '20px',
                        fontWeight: 700,
                        fontFamily: 'Poppins'
                      }}
                    >
                      Мы предлагаем
                    </Typography>

                    <Box sx={{ ml: '20px' }}>
                      <FormInput
                        field="description"
                        label="Мы предлагаем"
                        variant="outlined"
                        multiline
                      />
                    </Box>
                  </Stack>

                  <Stack sx={{ gap: '20px' }}>
                    <Typography
                      variant="h6"
                      sx={{
                        color: (theme) => theme.palette.grey[800],
                        fontSize: '20px',
                        fontWeight: 700,
                        fontFamily: 'Poppins'
                      }}
                    >
                      Что надо делать
                    </Typography>

                    <Box sx={{ ml: '20px' }}>
                      <FormInput
                        field="advantages"
                        label="Что надо делать"
                        variant="outlined"
                        multiline
                      />
                    </Box>
                  </Stack>

                  <Stack sx={{ gap: '20px' }}>
                    <Typography
                      variant="h6"
                      sx={{
                        color: (theme) => theme.palette.grey[800],
                        fontSize: '20px',
                        fontWeight: 700,
                        fontFamily: 'Poppins'
                      }}
                    >
                      Оплата
                    </Typography>

                    <Box sx={{ ml: '20px' }}>
                      <FormInput field="payment" label="Оплата" variant="outlined" multiline />
                    </Box>
                  </Stack>

                  <Stack sx={{ gap: '20px' }}>
                    <Typography
                      variant="h6"
                      sx={{
                        color: (theme) => theme.palette.grey[800],
                        fontSize: '20px',
                        fontWeight: 700,
                        fontFamily: 'Poppins'
                      }}
                    >
                      Город
                    </Typography>
                    <Box sx={{ ml: '20px' }}>
                      <FormControl fullWidth error={touched['cities'] && Boolean(errors['cities'])}>
                        <InputLabel id={'cities'}>Город</InputLabel>
                        <Select
                          labelId="cities"
                          id="cities"
                          name="cities"
                          label="Город"
                          multiple
                          value={values['cities']}
                          onChange={(e) => {
                            setFieldValue('cities', e.target.value)
                          }}
                        >
                          {CreateVacancyModel?.cities?.map((city: City) => (
                            <MenuItem key={city.id} value={city.id}>
                              {city.name}
                            </MenuItem>
                          ))}
                        </Select>
                        {touched['cities'] && <FormHelperText>{errors['cities']}</FormHelperText>}
                      </FormControl>
                    </Box>
                  </Stack>

                  <Stack sx={{ gap: '20px' }}>
                    <Typography
                      variant="h6"
                      sx={{
                        color: (theme) => theme.palette.grey[800],
                        fontSize: '20px',
                        fontWeight: 700,
                        fontFamily: 'Poppins'
                      }}
                    >
                      Картинка
                    </Typography>

                    <Box sx={{ ml: '20px' }}>
                      <UploadImageField field="image" imgSx={{ borderRadius: 0 }} />
                    </Box>
                  </Stack>
                </Stack>
              </Stack>
            </form>
          )}
        </Formik>
      )}
    </Stack>
  )
}

export default observer(CreateVacancy)
