import { useEffect } from 'react'
import * as yup from 'yup'
import { Formik } from 'formik'
import { observer } from 'mobx-react-lite'
import { Avatar, Box, Button, Divider, Paper, Stack, Typography } from '@mui/material'

import { PageHeader } from 'widgets'
import { commonStringValidation, fileValidationRequired } from 'shared/validations'
import { DeleteItem, FormInput, Input, Spinner, UploadImageField } from 'shared/ui'

import { AboutCompanyModel } from './model'

function AboutCompany() {
  useEffect(() => {
    AboutCompanyModel.fetch()
  }, [])

  const validationSchema = yup.object().shape({
    description: commonStringValidation(),
    image: fileValidationRequired()
  })

  return (
    <Stack>
      <PageHeader pageName="О компании" />

      {AboutCompanyModel.loading.has ? (
        <Spinner />
      ) : (
        <Stack component={Paper} elevation={3} sx={{ display: 'flex', p: 5 }}>
          <Stack sx={{ width: '500px', gap: '60px' }}>
            {AboutCompanyModel.abouts.map((about) => (
              <Stack key={about.id} sx={{ gap: '20px' }}>
                <Stack sx={{ gap: '20px' }}>
                  <Stack>
                    <Typography
                      variant="h6"
                      sx={{
                        color: (theme) => theme.palette.grey[800],
                        fontSize: '20px',
                        fontWeight: 700,
                        fontFamily: 'Poppins'
                      }}
                    >
                      Фото “О компании”
                    </Typography>

                    <Box sx={{ ml: '20px' }}>
                      <Avatar
                        src={about.image_path}
                        sx={{ width: 300, height: 380, borderRadius: 0 }}
                      />
                    </Box>
                  </Stack>
                </Stack>

                <Stack sx={{ gap: '20px' }}>
                  <Typography
                    variant="h6"
                    sx={{
                      color: (theme) => theme.palette.grey[800],
                      fontSize: '20px',
                      fontWeight: 700,
                      fontFamily: 'Poppins'
                    }}
                  >
                    Текст “О компании”
                  </Typography>

                  <Box sx={{ ml: '20px' }}>
                    <Input
                      value={about.description}
                      multiline
                      label="Описание"
                      variant="outlined"
                      disabled
                      sx={{
                        '& .MuiInputBase-input.Mui-disabled': {
                          WebkitTextFillColor: '#454545'
                        }
                      }}
                    />
                  </Box>
                </Stack>

                <DeleteItem
                  name="о нас"
                  onDelete={() => {
                    if (about.id) {
                      AboutCompanyModel.delete(about.id)
                    }
                  }}
                />
              </Stack>
            ))}

            {AboutCompanyModel.abouts.length < 1 && (
              <Formik
                initialValues={{
                  image: null,
                  description: ''
                }}
                validationSchema={validationSchema}
                onSubmit={(values) => {
                  AboutCompanyModel.create({
                    image: values.image,
                    description: values.description
                  })
                }}
              >
                {({ handleSubmit }) => (
                  <form onSubmit={handleSubmit}>
                    <Stack sx={{ gap: '20px' }}>
                      <Stack>
                        <Typography
                          variant="h6"
                          sx={{
                            color: (theme) => theme.palette.grey[800],
                            fontSize: '20px',
                            fontWeight: 700,
                            fontFamily: 'Poppins'
                          }}
                        >
                          Фото “О компании”
                        </Typography>

                        <Box sx={{ ml: '20px' }}>
                          <UploadImageField field="image" imgSx={{ borderRadius: 0 }} />
                        </Box>
                      </Stack>

                      <Stack sx={{ gap: '20px' }}>
                        <Typography
                          variant="h6"
                          sx={{
                            color: (theme) => theme.palette.grey[800],
                            fontSize: '20px',
                            fontWeight: 700,
                            fontFamily: 'Poppins'
                          }}
                        >
                          Текст “О компании”
                        </Typography>

                        <Box sx={{ ml: '20px' }}>
                          <FormInput
                            field="description"
                            multiline
                            label="Описание"
                            variant="outlined"
                          />
                        </Box>
                      </Stack>

                      <Button
                        type="submit"
                        variant="contained"
                        sx={{ height: 'fit-content', p: '4px 8px' }}
                      >
                        Сохранить
                      </Button>
                    </Stack>
                  </form>
                )}
              </Formik>
            )}

            <Divider color="text.secondary" />

            {AboutCompanyModel.contacts.map((contact) => (
              <Stack key={contact.id} sx={{ gap: '20px' }}>
                <Stack sx={{ gap: '20px' }}>
                  <Stack>
                    <Typography
                      variant="h6"
                      sx={{
                        color: (theme) => theme.palette.grey[800],
                        fontSize: '20px',
                        fontWeight: 700,
                        fontFamily: 'Poppins'
                      }}
                    >
                      Фото “Контакты”
                    </Typography>

                    <Box sx={{ ml: '20px' }}>
                      <Avatar
                        src={contact.image_path}
                        sx={{ width: 300, height: 380, borderRadius: 0 }}
                      />
                    </Box>
                  </Stack>
                </Stack>

                <Stack sx={{ gap: '20px' }}>
                  <Typography
                    variant="h6"
                    sx={{
                      color: (theme) => theme.palette.grey[800],
                      fontSize: '20px',
                      fontWeight: 700,
                      fontFamily: 'Poppins'
                    }}
                  >
                    Текст “Контакты”
                  </Typography>

                  <Box sx={{ ml: '20px' }}>
                    <Input
                      value={contact.description}
                      multiline
                      label="Описание"
                      variant="outlined"
                      disabled
                      sx={{
                        '& .MuiInputBase-input.Mui-disabled': {
                          WebkitTextFillColor: '#454545'
                        }
                      }}
                    />
                  </Box>
                </Stack>

                <DeleteItem
                  name="менеджера"
                  onDelete={() => {
                    if (contact.id) {
                      AboutCompanyModel.deleteContact(contact.id)
                    }
                  }}
                />
              </Stack>
            ))}

            {AboutCompanyModel.contacts.length < 1 && (
              <Formik
                initialValues={{
                  image: null,
                  description: ''
                }}
                validationSchema={validationSchema}
                onSubmit={(values) => {
                  AboutCompanyModel.createContact({
                    image: values.image,
                    description: values.description
                  })
                }}
              >
                {({ handleSubmit }) => (
                  <form onSubmit={handleSubmit}>
                    <Stack sx={{ gap: '20px' }}>
                      <Stack>
                        <Typography
                          variant="h6"
                          sx={{
                            color: (theme) => theme.palette.grey[800],
                            fontSize: '20px',
                            fontWeight: 700,
                            fontFamily: 'Poppins'
                          }}
                        >
                          Фото “Контакты”
                        </Typography>

                        <Box sx={{ ml: '20px' }}>
                          <UploadImageField field="image" imgSx={{ borderRadius: 0 }} />
                        </Box>
                      </Stack>

                      <Stack sx={{ gap: '20px' }}>
                        <Typography
                          variant="h6"
                          sx={{
                            color: (theme) => theme.palette.grey[800],
                            fontSize: '20px',
                            fontWeight: 700,
                            fontFamily: 'Poppins'
                          }}
                        >
                          Текст “Контакты”
                        </Typography>

                        <Box sx={{ ml: '20px' }}>
                          <FormInput
                            field="description"
                            multiline
                            label="Описание"
                            variant="outlined"
                          />
                        </Box>
                      </Stack>

                      <Button
                        type="submit"
                        variant="contained"
                        sx={{ height: 'fit-content', p: '4px 8px' }}
                      >
                        Сохранить
                      </Button>
                    </Stack>
                  </form>
                )}
              </Formik>
            )}
          </Stack>
        </Stack>
      )}
    </Stack>
  )
}

export default observer(AboutCompany)
