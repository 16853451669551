import { useMemo, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { useNavigate } from 'react-router-dom'
import { Box, Button, Grid, Paper, Stack, TableContainer } from '@mui/material'

import { PageHeader } from 'widgets'
import { ROUTES } from 'shared/consts'
import { CommonTable, Spinner } from 'shared/ui'

import { VacanciesModel, getColumns } from './model'

function Vacancies() {
  const navigate = useNavigate()

  useEffect(() => {
    VacanciesModel.fetch()
  }, [])

  const columns = useMemo(() => getColumns(), [])

  return (
    <Stack spacing={2}>
      <PageHeader pageName="Вакансии">
        <Button
          onClick={() => navigate(ROUTES.VACANCIES_NEW)}
          variant="contained"
          sx={{ height: 'fit-content', mt: '16px', padding: '4px 16px' }}
        >
          Добавить
        </Button>
      </PageHeader>
      <Grid container direction="column">
        <Grid elevation={4} component={Paper} container direction="column">
          <Box sx={{ display: 'flex', gap: '20px', m: '40px 23px' }}></Box>
          <Grid item>
            {VacanciesModel.loading.has ? (
              <Spinner />
            ) : (
              <TableContainer component={Paper} sx={{ mb: '300px' }}>
                <CommonTable data={VacanciesModel.vacancies} columns={columns} />
              </TableContainer>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Stack>
  )
}

export default observer(Vacancies)
